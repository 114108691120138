import { paginatedSchema, paginationRequestParams } from '@medizzy/api';
import type { APIMethodParams } from '@medizzy/api';
import { z } from 'zod';
import { baseLearningCategorySchema } from './fetch-learning-category.ts';

const fetchMCQsRequestSchema = z.object({
  category: z.number().optional(),
  type: z
    .enum([
      'all',
      'quiz',
      'promotional',
      'incorrect',
      'one_random',
      'latest',
      'preview',
      'review',
    ])
    .optional(),
  nextFromParent: z.number().optional(),
  mode: z.enum(['shuffle_choices']).optional(),
  ...paginationRequestParams,
});

export const mcqSchema = z.object({
  id: z.number(),
  answered: z.boolean().optional(),
  correctAnswer: z.boolean().optional(),
  availablePoints: z.number().optional(),
  next: z.number().optional(),
  contents: z.string().min(1),
  explanation: z.string().optional(),
  explanationOld: z.string().optional(),
  learningCategory: baseLearningCategorySchema,
  topLearningCategory: baseLearningCategorySchema,
  keyInformation: z.string().optional(),
  choices: z
    .object({
      id: z.number(),
      description: z.string().min(1),
      explanation: z.string().optional(),
      selected: z.boolean(),
      correct: z.boolean(),
    })
    .array(),
  reference: z.string().optional(),
  review: z
    .object({
      completed: z.boolean(),
      reviewers: z.array(
        z.object({
          id: z.number(),
          name: z.string(),
          avatarUrl: z.string().optional(),
        }),
      ),
    })
    .optional(),
  tags: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .array(),
  averageRating: z
    .object({
      difficulty: z.number().nullable(),
      quality: z.number().nullable(),
    })
    .optional(),
  rating: z
    .object({
      difficulty: z.number().nullable(),
      quality: z.number().nullable(),
    })
    .optional(),
  note: z.string().optional(),
});

const fetchMCQsResponseSchema = paginatedSchema(mcqSchema);

export type FetchMCQsParams = z.infer<typeof fetchMCQsRequestSchema>;
export type FetchMCQsResponse = z.infer<typeof fetchMCQsResponseSchema>;
export type MCQ = z.infer<typeof mcqSchema>;

export function fetchMCQs({
  client,
  params,
}: APIMethodParams<z.infer<typeof fetchMCQsRequestSchema>>) {
  return client
    .url('/v2/learning/mcq')
    .query(fetchMCQsRequestSchema.parse(params))
    .get()
    .valid(fetchMCQsResponseSchema);
}
