import { useNextMCQDeckFromParent } from '@medizzy/learning';
import { Link, useNavigate } from '@remix-run/react';
import type { MouseEvent } from 'react';
import { useTracking } from 'react-tracking';
import { toast } from 'sonner';
import { Button, buttonVariants } from '~/components/button';
import { HStack } from '~/components/stack';
import { DeckMaterialCount } from '~/features/learning/deck/deck-material-count';
import { ProgressBar } from '~/features/learning/progress-bar';
import { ProgressPill } from '~/features/learning/progress-pill';
import { cn } from '~/utils/cn';
import { ROUTES } from '~/utils/routes';

interface DeckCardProps {
  id: number | undefined;
  coverImgId: number | undefined;
  title: string;
  progress: {
    correct: number;
    incorrect: number;
    total: number;
  };
  questions: number;
  decks: number;
  variant?: 'learn' | 'review' | 'preview';
  withActions?: boolean;
}

const FALLBACK_BG_DECK_ID = '24132129';

export function DeckCard({
  id,
  coverImgId,
  title,
  progress,
  questions,
  decks,
  variant = 'learn',
}: DeckCardProps) {
  const navigate = useNavigate();
  const isBrowsable = decks > 0;
  const isProgressPillVisible = variant === 'learn' || variant === 'preview';
  const { trackEvent } = useTracking();
  const getNextFromParent = useNextMCQDeckFromParent({
    onSuccess: ({ id, name }) => {
      toast.success('The learning session will start shortly');
      navigate(ROUTES.SOLVE.DECK({ id }));
      trackEvent({
        event: 'deck_start_learning',
        deck_id: id,
        deck_name: name,
      });
    },
    onError: () => {
      toast.error('We could not select a deck for you. Please try again!');
    },
  });

  function onStartLearning(event: MouseEvent) {
    event.stopPropagation();
    getNextFromParent.mutate({ id: id as number, type: 'mcq' });
  }

  function onCardClick() {
    if (variant !== 'learn') {
      return;
    }

    trackEvent({
      event: 'deck_start_learning',
      deck_id: id,
      deck_name: title,
    });

    if (isBrowsable) {
      getNextFromParent.mutate({ id: id as number, type: 'mcq' });
    } else {
      navigate(ROUTES.SOLVE.DECK({ id: id as number }));
    }
  }

  return (
    <span
      className={cn('block bg-card rounded-2xl p-4 relative', {
        'cursor-pointer': variant === 'learn',
      })}
      onClick={onCardClick}
    >
      <span className="flex flex-col gap-3 relative z-10 pt-[60px] h-full justify-end">
        <span className="font-semibold">{title}</span>
        <ProgressBar
          {...progress}
          correctClassName={cn({
            'bg-blue-400': variant === 'review',
          })}
        />
        <span className="flex items-center justify-between gap-2">
          <DeckMaterialCount decks={decks} questions={questions} />
          {isProgressPillVisible && (
            <ProgressPill
              correct={progress.correct}
              incorrect={progress.incorrect}
            />
          )}
        </span>
        {variant === 'learn' && (
          <HStack className="w-full gap-2">
            {isBrowsable && (
              <Link
                to={ROUTES.DECKS.DECK({ id: id as number })}
                className={buttonVariants({
                  variant: 'outline',
                  className: 'flex-1',
                })}
                onClick={(event) => {
                  event.stopPropagation();
                  trackEvent({
                    event: 'deck',
                    deck_id: id,
                    deck_name: title,
                  });
                }}
              >
                Browse
              </Link>
            )}
            {!isBrowsable && (
              <Link
                to={ROUTES.SOLVE.DECK({ id: id as number })}
                className={buttonVariants({
                  variant: 'outline',
                  className: 'flex-1',
                })}
                onClick={(event) => {
                  event.stopPropagation();
                  trackEvent({
                    event: 'deck_start_learning',
                    deck_id: id,
                    deck_name: title,
                  });
                }}
              >
                Learn
              </Link>
            )}
            {isBrowsable && (
              <Button
                variant="outline"
                className="flex-1"
                isLoading={getNextFromParent.isPending}
                onClick={onStartLearning}
              >
                Learn
              </Button>
            )}
          </HStack>
        )}
      </span>
      <span className="rounded-tl-2xl rounded-tr-2xl absolute h-[100px] left-1 right-1 top-1 overflow-hidden z-0">
        <img
          src={`/img/decks/${coverImgId ?? FALLBACK_BG_DECK_ID}.jpg`}
          alt={title}
          className="w-full h-full object-cover"
        />
        <span className="absolute inset-0 bg-gradient-to-b from-card/50 to-card" />
      </span>
    </span>
  );
}
