import type { APIMethodParams } from '@medizzy/api';
import { signInResponseSchema } from '@medizzy/auth';
import { z } from 'zod';

export const fetchUserRequestSchema = z.object({
  id: z.number().or(z.literal('me')),
});

export const fetchUserResponseSchema = signInResponseSchema.pick({
  alltimeActivityPoints: true,
  alltimeRankingPoints: true,
  avatarUrl: true,
  boardingCompleted: true,
  email: true,
  favouritePostsCount: true,
  followedSections: true,
  followedSectionsCount: true,
  followedTagsCount: true,
  followersCount: true,
  id: true,
  isVerified: true,
  level: true,
  name: true,
  numberOfPosts: true,
  payments: true,
  refreshToken: true,
  roles: true,
  settings: true,
  token: true,
  tokenExpireDate: true,
  university: true,
  username: true,
  verificationCheck: true,
});

export type FetchUserParams = z.infer<typeof fetchUserRequestSchema>;
export type FetchUserResponse = z.infer<typeof fetchUserResponseSchema>;
export type User = FetchUserResponse;

export function fetchUser({
  client,
  params,
}: APIMethodParams<FetchUserParams>) {
  const { id } = fetchUserRequestSchema.parse(params);
  return client.get(`/users/${id}`).valid(fetchUserResponseSchema);
}
