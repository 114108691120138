import { isHTTPClientError } from '@medizzy/http-client';
import { QueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { ZodError } from 'zod';

export function useProvideQueryClient() {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            throwOnError: (error) => {
              return import.meta.env.DEV && error instanceof ZodError;
            },
            retry: (count, error) => {
              if (error instanceof ZodError) {
                return false;
              }

              if (
                isHTTPClientError(error) &&
                (error.status === 401 || error.status === 402)
              ) {
                return false;
              }

              return count < 3;
            },
          },
          mutations: {
            throwOnError: (error) => {
              return import.meta.env.DEV && error instanceof ZodError;
            },
          },
        },
      }),
  );

  return { queryClient };
}
