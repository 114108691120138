import { createAPIQueryHooks } from '@medizzy/api';
import type { APIMethodParams } from '@medizzy/api';
import { fetchUser } from '../methods/fetch-user.ts';

export const [useUser, useUserSuspense] = createAPIQueryHooks({
  queryFn: fetchUser,
  queryKey: ['user'],
});

export function useCurrentUser(
  props?: Omit<Parameters<typeof useUser>[0], 'params'>,
) {
  return useUser({
    ...props,
    params: { id: 'me' },
  });
}

useCurrentUser.getQueryKey = () =>
  useUser.getQueryKey({ params: { id: 'me' } });

useCurrentUser.getQueryFn = ({ client }: APIMethodParams) =>
  useUser.getQueryFn({ client, params: { id: 'me' } });
