import { useHTTPClient } from '@medizzy/api';
import type { HTTPClientError } from '@medizzy/http-client';
import { type UseMutationOptions, useMutation } from '@tanstack/react-query';
import type { LearningCategory } from '../methods/fetch-learning-category.ts';
import { type MCQ, fetchMCQs } from '../methods/fetch-mcqs.ts';

export type UseGetNextSetFromParentProps = UseMutationOptions<
  Pick<LearningCategory, 'id' | 'name'>,
  HTTPClientError,
  | { id: MCQ['id']; type: 'mcq'; category?: number }
  | { id: number; type: 'flashcard'; category?: number }
>;

export function useNextMCQDeckFromParent(props?: UseGetNextSetFromParentProps) {
  const client = useHTTPClient();

  return useMutation({
    ...props,
    mutationFn: async ({ id, type, category: categoryId }) => {
      if (type === 'flashcard') {
        throw new Error('Not supported');
      }

      const data = await fetchMCQs({
        client,
        params: { nextFromParent: id, category: categoryId, size: 1 },
      });
      const category = data.content[0].learningCategory;

      if (!category.id) {
        throw new Error(
          `useGetNextSetFromParent: no category found for params id=${id}, type=${type}`,
        );
      }

      return category as Pick<LearningCategory, 'id' | 'name'>;
    },
  });
}
