import type { APIMethodParams } from '@medizzy/api';
import { z } from 'zod';

const tagSchema = z.object({
  id: z.number(),
  name: z.string(),
  displayName: z.string(),
  slug: z.string(),
  count: z.number(),
  category: z.boolean(),
  color: z.string().optional(),
  thumbnailUrl: z.string().url().optional(),
});

export const baseLearningCategorySchema = z.object({
  id: z.number(),
  name: z.string(),
  thumbnailUrl: z.string().optional(),
  primaryColor: z.string().optional(),
  secondaryColor: z.string().optional(),
  childrenCount: z.number(),
  categoryTag: tagSchema.nullable(),
  order: z.number().optional(),
  topLearningCategory: z
    .object({
      categoryTag: tagSchema.partial().nullable(),
      childrenCount: z.number().optional(),
      id: z.number().optional(),
      name: z.string().optional(),
      primaryColor: z.string().optional(),
      secondaryColor: z.string().optional(),
      thumbnailUrl: z.string().nullable().optional(),
    })
    .optional(),
  owner: z
    .object({
      id: z.number(),
      name: z.string(),
      avatarUrl: z.string().optional(),
    })
    .optional(),
  visibility: z.enum(['PUBLIC', 'PRIVATE']).optional(),
  access: z.enum(['ALL', 'FREE', 'PREMIUM']).optional(),
  tags: tagSchema.array().optional(),
  start: z.string().datetime({ offset: true }).optional(),
  stop: z.string().datetime({ offset: true }).optional(),
  tutorMode: z.boolean().optional(),
  flashcardsInfo: z
    .object({
      count: z.number(),
      first: z
        .object({
          id: z.number(),
        })
        .optional(),
      favouriteCount: z.number().optional(),
      rightAnswers: z.number().optional(),
      wrongAnswers: z.number().optional(),
    })
    .optional(),
  mcqInfo: z
    .object({
      count: z.number(),
      totalMcqCount: z.number(),
      reviewed: z.number(),
      first: z
        .object({
          id: z.number(),
        })
        .optional(),
      favouriteCount: z.number().optional(),
      rightAnswers: z.number().optional(),
      wrongAnswers: z.number().optional(),
    })
    .optional(),
});

export type LearningCategory = z.infer<typeof baseLearningCategorySchema> & {
  subcategories?: LearningCategory[];
};

export const fetchLearningCategoryResponseSchema: z.ZodType<LearningCategory> =
  baseLearningCategorySchema.extend({
    subcategories: z.lazy(() => baseLearningCategorySchema.array()).optional(),
  });

export const fetchLearningCategoryRequestSchema = z.object({
  id: z.number(),
});

export function fetchLearningCategory({
  client,
  params,
}: APIMethodParams<z.infer<typeof fetchLearningCategoryRequestSchema>>) {
  return client
    .url(
      `/v2/learning/categories/${fetchLearningCategoryRequestSchema.parse(params).id}`,
    )
    .get()
    .valid(fetchLearningCategoryResponseSchema);
}

export type FetchLearningCategoryParams = z.infer<
  typeof fetchLearningCategoryRequestSchema
>;
export type FetchLearningCategoryResponse = Awaited<
  ReturnType<typeof fetchLearningCategory>
>;
